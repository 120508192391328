import React from "react";
import PropTypes from "prop-types";
import Button from "../custom-widgets/button";

/**
 * This is the component to render the branch review content.
 *
 * @param {Object} props - The object containing props.
 * @param {string} props.ReviewTitle - Review title from Strapi/graphQL query.
 * @param {string} props.ReviewText - Review text from Strapi/graphQL query.
 * @param {string} props.AuthorsName - Review author's name from Strapi/graphQL query.
 * @param {string} props.titleClass - Classes for title review.
 * @param {string} props.textClass - Classes for text review.
 * @param {string} props.authorColClass - Classes for author column div container.
 * @param {string} props.authorClass - Classes for author <p> element.
 * @param {string} props.buttonColClass - Classes for button column div container.
 * @param {Object} props.button - Object for the review button.
 *   - `id`: ID for button review.
 *   - `class`: Button classes.
 *   - `text`: Button text.
 *   - `textXs`: Short text for the button.
 *   - `showIcon`: Boolean prop.
 * @returns {React.JSX.Element} Returns the JSX component to render.
 */
const BranchReview = ({
  ReviewTitle = "",
  ReviewText = "",
  AuthorsName = "",
  titleClass = "h4 my-3 text-black",
  textClass = "text-secondary font-italic mb-1 mb-sm-2 mb-md-3",
  authorColClass = "col-lg-5",
  authorClass = "mt-2 mb-3 text-black",
  buttonColClass = "col-lg-7",
  branch_location = {
    PageURL: ""
  },
  button = {
    id: "branch-review-1-button",
    class: "btn btn-link px-3",
    text: "See Branch Details",
    textXs: "Branch Details",
    showIcon: false
  }
}) => {
  //console.log("BranchReview props: ", props);

  let myButton = { ...button };
  myButton.url = myButton.url || branch_location.PageURL;

  return (
    <>
      <div className={titleClass}>{ReviewTitle}</div>
      <div className={textClass}>
        <em>&ldquo;{ReviewText}&rdquo;</em>
      </div>
      <div className="row d-none d-lg-flex">
        <div className={buttonColClass}>
          <Button {...myButton} />
        </div>
        <div className={authorColClass}>
          <p className={authorClass}>
            <strong>{AuthorsName}</strong>
          </p>
        </div>
      </div>
      <div className="row d-flex d-lg-none">
        <div className={authorColClass}>
          <p className={authorClass}>
            <strong>{AuthorsName}</strong>
          </p>
        </div>
        <div className={buttonColClass}>
          <Button {...myButton} />
        </div>
      </div>
    </>
  );
};
export default BranchReview;

BranchReview.propTypes = {
  ReviewTitle: PropTypes.string,
  ReviewText: PropTypes.string,
  AuthorsName: PropTypes.string,
  titleClass: PropTypes.string,
  textClass: PropTypes.string,
  authorColClass: PropTypes.string,
  authorClass: PropTypes.string,
  buttonColClass: PropTypes.string,
  button: PropTypes.shape({
    id: PropTypes.string,
    class: PropTypes.string,
    text: PropTypes.string,
    textXs: PropTypes.string,
    showIcon: PropTypes.bool
  })
};

