import React, { useState } from "react";
//import { useStaticQuery, graphql } from "gatsby";

// Font Awesome Icons
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from "../../components/custom-widgets/icon";

// React Bootstrap Imports
import Carousel from "react-bootstrap/Carousel";

// Components
import BranchReview from "./branch-review";

// Styles
import "./branch-reviews-carousel.bootstrap.scss";

const BranchReviewCarousel = () => {
  // const branchReviewsData = useStaticQuery(graphql`
  //   {
  //     reviews: allStrapiBranchReviews(
  //       sort: { fields: DisplayOrder, order: ASC }
  //       filter: { ActiveOnSearchPage: { eq: true } }
  //     ) {
  //       nodes {
  //         id
  //         ReviewTitle
  //         ReviewText
  //         AuthorsName
  //         PageURL
  //       }
  //     }
  //   }
  // `);
  //const reviewNodes = branchReviewsData.reviews.nodes;
  // TODO import from Strapi
  const reviewNodes = [
    {
      id: 1,
      ReviewTitle: "Seattle - Columbia City, Washington",
      ReviewText:
        "Allison and Bill and WaFd were instrumental in helping me navigate the PPP process for COVID 19 small business relief. Thanks for your help and support your guys were great!", // from Strapi/graphQL query
      AuthorsName: "Erik N",
      branch_location: {
        PageURL: "/locations/washington/seattle/columbia-city"
      }
    },
    {
      id: 2,
      ReviewTitle: "Bend - Bluff Drive, Oregon",
      ReviewText:
        'We have always received great service from the Bluff branch of WaFd. Just last week I called Nick Silva to see about getting help applying for a PPP Loan. Not only did he give me the information needed, but he helped me get the loan application turned into the SBA in record time. Thank you Nick! I never feel like a "small" account when working with the Bluff branch. We are always greeted by name treated with respect.', // from Strapi/graphQL query
      AuthorsName: "Stephanie Z.",
      branch_location: {
        PageURL: "/locations/oregon/bend/bluff-drive"
      }
    },
    {
      id: 3,
      ReviewTitle: "Boise – Federal Way, Idaho",
      ReviewText:
        "Amazing customer service! Kyle Helms handled our estate POA matters with professionalism and made the whole experience very easy. His relaxing demeanor and confidence assured us that all would be well, and it certainly is. I highly recommend this branch and Kyle for any important and sensitive financial dealings!", // from Strapi/graphQL query
      AuthorsName: "Chris J.",
      branch_location: {
        PageURL: "/locations/idaho/boise/federal-way"
      }
    },
    {
      id: 4,
      ReviewTitle: "Las Vegas – Summerlin, Nevada",
      ReviewText:
        "Amazing team! WaFd really stepped up during this difficult time helping people with the PPP Loan. I've been a client for a few years and I've always been satisfied with their customer service!",
      AuthorsName: "Mathieu C.",
      branch_location: {
        PageURL: "/locations/nevada/las-vegas/summerlin"
      }
    },
    {
      id: 5,
      ReviewTitle: "Salt Lake City – Sugarhouse, Utah",
      ReviewText:
        "Lydia and her crew are truly the best of the best!! How I enjoy interacting with them on a weekly basis!!",
      AuthorsName: "Steven L.",
      branch_location: {
        PageURL: "/locations/utah/salt-lake-city/sugarhouse"
      }
    },
    {
      id: 6,
      ReviewTitle: "Tucson – Broadway Blvd, Arizona",
      ReviewText:
        "Great Customer Service. Travis Duran was a pleasure to deal with during this difficult transaction. It was difficult because of a snag with SBA and he was a true professional navigating thorough this transaction. He is a credit to Washington Federal.",
      AuthorsName: "Anna B",
      branch_location: {
        PageURL: "/locations/arizona/tucson/4910-broadway-blvd"
      }
    },
    {
      id: 7,
      ReviewTitle: "Denton, Texas",
      ReviewText:
        'In over 50 years of banking, the Denton, TX Washington Federal is the best banking experience we\'ve ever had. The staff is warm, welcoming & never makes us feel rushed. They make us feel special & take time to explain the available banking options. The staff even called my husband to sing "Happy Birthday". The Denton staff is extremely friendly, caring & always make us feel welcome.',
      AuthorsName: "Donald K.",
      branch_location: {
        PageURL: "/locations/texas/denton"
      }
    },
    {
      id: 8,
      ReviewTitle: "Santa Fe - Downtown, New Mexico",
      ReviewText:
        "Sean Romero went above and beyond to help me with my home financing needs. He was responsive and fast, he was a clear communicator and I felt that he was the hardest lender I have worked with. You are in good hands with him.",
      AuthorsName: "Amina A.",
      branch_location: {
        PageURL: "/locations/new-mexico/santa-fe/downtown"
      }
    }
  ];
  //console.log("reviewNodes: ", reviewNodes);

  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState("right");

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    setDirection(direction);
  };

  const getCarouselElement = function (props) {
    return (
      <Carousel
        id={props.id}
        className={props.className}
        activeIndex={index}
        direction={direction}
        interval={80000}
        onSelect={handleSelect}
        controls={true}
        indicators={false}
        touch={true}
        prevIcon={
          <div className="carousel-control-prev-icon">
            <Icon class="text-secondary fa-2x" lib="fas" name="chevron-left" />
            <span className="sr-only">Previous</span>
          </div>
        }
        nextIcon={
          <div className="carousel-control-next-icon">
            <Icon class="text-secondary fa-2x" lib="fas" name="chevron-right" />
            <span className="sr-only">Next</span>
          </div>
        }
      >
        {props.children}
      </Carousel>
    );
  };

  const getReviewCarouselItemsLg = function () {
    const carouselItems = [];

    for (let i = 0; i < reviewNodes.length; i += 2) {
      const review1 = reviewNodes[i];
      const review2 = reviewNodes[i + 1];

      carouselItems.push(
        <Carousel.Item key={review1.id} id={`branch-review-${review1.id}`}>
          <Carousel.Caption>
            <div className="row row-cols-md-2 text-left">
              <div className="col border-md-right">
                <BranchReview {...review1} />
              </div>
              <div className="col">
                <BranchReview {...review2} />
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      );
    }

    return carouselItems;
  };

  const getReviewCarouselItemsSm = function () {
    const carouselItems = [];

    for (let i = 0; i < reviewNodes.length; i++) {
      const review = reviewNodes[i];

      carouselItems.push(
        <Carousel.Item key={review.id} id={`branch-review-${review.id}`}>
          <Carousel.Caption>
            <BranchReview {...review} />
          </Carousel.Caption>
        </Carousel.Item>
      );
    }

    return carouselItems;
  };

  return (
    <section id="branch-reviews-section" className="container pb-0">
      {getCarouselElement({
        id: "branch-reviews-carousel-desktop",
        className: "d-none d-md-block",
        children: getReviewCarouselItemsLg()
      })}
      {getCarouselElement({
        id: "branch-reviews-carousel-mobile",
        className: "d-block d-md-none",
        children: getReviewCarouselItemsSm()
      })}
      {/*<Carousel
        id="branch-reviews-carousel"
        className=""
        activeIndex={index}
        onSelect={handleSelect}
        direction={direction}
        interval={null}
        controls={true}
        indicators={false}
        touch={true}
        prevIcon={
          <div className="carousel-control-prev-icon">
            <Icon class="text-secondary fa-2x" lib="fas" name="chevron-left" />
            <span className="sr-only">Previous</span>
          </div>
        }
        nextIcon={
          <div className="carousel-control-next-icon">
            <Icon class="text-secondary fa-2x" lib="fas" name="chevron-right" />
            <span className="sr-only">Next</span>
          </div>
        }
      >
        {reviewNodes.map((reviewNode) => {
          return (
            <Carousel.Item key={reviewNode.id} id={`branch-review-${reviewNode.id}`}>
              <Carousel.Caption>
                <div className="row">
                  <div className="col-lg-6 border-right">
                    <BranchReview {...reviewNode} />
                  </div>
                  <div className="col-lg-6">
                    <BranchReview {...reviewNode} />
                  </div>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>*/}
    </section>
  );
};

export default BranchReviewCarousel;
