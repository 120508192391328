import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

// Layout
import SecondaryLanding from "../../layouts/secondary-landing";

// Components

import SEO from "../../components/seo/seo";
import BranchSearch from "../../components/branch-search/branch-search";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import BranchReviewCarousel from "../../components/branch-info/branch-review-carousel";
import WaFdBankStatesMap from "../../assets/wafd-bank-9-states-plain-map.svg";
import BestBanksDefault from "../../components/best-banks/best-banks-default";
import NoLinkCards from "../../components/card/no-link-cards";

import { usePageUtagDataContext } from "../../contexts/page-utag-data-context";

const Locations = () => {
  const imageData = useStaticQuery(graphql`
    {
      checkingImage: file(relativePath: { eq: "cards/thumbnail-checking-030524.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      savingsImage: file(relativePath: { eq: "cards/thumbnail-savings-030524.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      mortgageImage: file(relativePath: { eq: "cards/thumbnail-home-loans-030524.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const title = "WaFd Bank Locations";

  const SEOData = {
    title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Welcome to WaFd Bank we provide checking, savings, and mortgage services in the communities we serve. Visit a branch near you today!"
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/locations"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      active: true,
      title: "Locations"
    }
  ];

  const [geoState, setGeoState] = useState(null);
  const [geoCity, setGeoCity] = useState(null);
  const pageDataContext = usePageUtagDataContext();
  const [pageData, setPageData] = useState({});
  const [isUtagJsLoaded, setIsUtagJsLoaded] = useState(false);

  function setUtagData(pageData) {
    Object.assign(window.utag.data, {
      page_section: pageData.page_section,
      page_category: pageData.page_category,
      page_name: pageData.page_name,
      tealium_event: pageData.tealium_event,
      site_language: pageData.site_language
    });
  }

  useEffect(() => {
    if (pageDataContext === null || pageDataContext === undefined || pageDataContext.tealium_event === null) {
      return null;
    }
    setPageData({ ...pageDataContext });
  }, [pageDataContext]);

  useEffect(() => {
    if (!isUtagJsLoaded) return null;
    setUtagData(pageData);
    let script = document.createElement("script");
    const scriptText = document.createTextNode(
      `window.utag?.view({ "page_section": "${pageData.page_section}", "page_category": "${pageData.page_category}", "page_name": "${pageData.page_name}", "site_language": "${pageData.site_language}"});`
    );
    script.id = "tealium-utag-view-gatsby";
    script.appendChild(scriptText);
    document.body.appendChild(script);

    // Washington, Oregon, Idaho, New Mexico, Nevada, Arizona, Texas and Utah
    let validStates = ["wa", "or", "id", "nm", "nv", "az", "az", "tx", "ut"];
    let searchState = window.utag.data.geo_state != null ? window.utag.data.geo_state.toLowerCase() : null;

    let validStateFound = validStates.find((state) => state === searchState);
    if (validStateFound != null) {
      setGeoState(validStateFound);
      setGeoCity(window.utag.data.geo_city);
    }

    // cleanup on page tear down
    return (el) => {
      document.body.removeChild(script);
    };
  }, [pageData, isUtagJsLoaded]);

  useEffect(() => {
    function handleUtagScriptLoad() {
      if (typeof window?.utag?.view === "function") {
        setIsUtagJsLoaded(true);
      }
    }
    const utagScript = document.getElementById("utag-script");
    utagScript.addEventListener("load", handleUtagScriptLoad);
    return () => utagScript.removeEventListener("load", handleUtagScriptLoad);
  }, []);

  const threeCardsData = {
    sectionId: "locations-link-cards",
    cards: [
      {
        id: "locations-link-card-checking",
        title: "Checking That's Simple, Straightforward, Smart",
        titleClass: "text-success",
        text: "Your account should work hard for you. From standard tools like Bill Pay to benefits and rewards, you'll find a checking account to fit your needs. Plus, our great-looking debit cards will help you spend in style!",
        image: {
          imgVariable: imageData.checkingImage.childImageSharp.gatsbyImageData,
          altText: "Woman on her mobile phone while sitting cross-legged on a couch."
        },
        button: {
          id: "locations-link-card-checking-cta",
          text: "Compare Accounts",
          url: "/personal-banking/checking-account",
          class: "btn-primary btn-block",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      },
      {
        id: "locations-link-card-savings",
        title: "Savings Accounts to Help You Thrive",
        titleClass: "text-success",
        text: "With a variety of savings options, your money can work harder for you. We offer various options to fit your needs with the flexibility life requires.",
        image: {
          imgVariable: imageData.savingsImage.childImageSharp.gatsbyImageData,
          altText: "A mother and young son on vacation, Copenhagen train station."
        },
        button: {
          id: "locations-link-card-savings-cta",
          text: "Compare Accounts",
          url: "/personal-banking/savings-account",
          class: "btn-primary btn-block",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      },
      {
        id: "locations-link-card-mortgage",
        title: "Home Loans to Fit Your Life",
        titleClass: "text-success",
        text: "Wherever you are in life, a home loan should fit your needs. With first time home buyer, remodel, construction, and more options, our knowledgeable loan officers are here for you.",
        image: {
          imgVariable: imageData.mortgageImage.childImageSharp.gatsbyImageData,
          altText: "A happy couple sitting on their couch, laughing."
        },
        button: {
          id: "locations-link-card-mortgage-cta",
          text: "Learn More",
          url: "/personal-banking/home-loans",
          class: "btn-primary btn-block",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      }
    ]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section id="branch-locations-section" className="container">
        <h1>WaFd Bank Locations</h1>
        <div className="row">
          <div className="col-md-6 col-lg-3">
            <img className="w-100" src={WaFdBankStatesMap} alt="State Selection Map" />
          </div>
          <div className="col-md-6 col-lg-9">
            <h3>
              Even though WaFd Bank stretches across 9 states, at our heart we are your local bank. Whether you are
              looking for a mortgage for your dream home, banking services for your business, or home equity lines of
              credit, WaFd Bank is here to help.
            </h3>
          </div>
        </div>
      </section>
      <BranchSearch geo_city={geoCity} geo_state={geoState} />
      <BranchReviewCarousel />
      <NoLinkCards {...threeCardsData} />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default Locations;
